import api from "./core/api";
class Member {
    constructor(api) {
        this.api = api;
    }
    member(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member'), options
        );
    }
    cmlxBalance(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/cmlx/balance'), options
        );
    }
    itwBalance(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/cmlx/balance/itw'), options
        );
    }
    ticketBalance(options = {timeout: 60000}) {
        return this.api.client.get(
            this.api.getUrl('member/experience-ticket/balance'), options
        );
    };
    points(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/points'), options
        );
    }
    legalInfo(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/legal-info'), options
        );
    }
    sysBalance(options = {}) {
        return this.api.client.get(
            this.api.getUrl('member/cmlx/balance/wallet'), options
        );
    }
    getAbeyTansferForm() {
        return this.api.client.get(
            this.api.getUrl('member/abey-balance/transfer/private/from-wallet')
        );
    }
    setAbeyTansfer(amount, options = {}) {
        return this.api.client.post(
            this.api.getUrl('member/abey-balance/transfer/private/from-wallet'),
            {json: {amount: amount}, options}
        );
    }
    setOwnerId(ownerId, options) {
        return this.api.client.post(
            this.api.getUrl('member/owner-id'),
            {...options,  json: {ownerId} }
        );
    }
}

export default (new Member(api))
